import Head from "next/head";
import React from "react";
import { defaultMeta } from "./defaultMeta";

interface SEOProps {
  title?: string;
  description?: string;
  canonical?: string;
  image?: string;
}

const SEO: React.FC<SEOProps> = ({ title, description, canonical, image }) => (
  <Head>
    <title>
      {title ? title + " |" : undefined} {`Where Is Hot`}
    </title>
    <meta
      name="description"
      content={description ? description : defaultMeta.description}
    />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta property="og:type" content="website" />
    <meta name="og:title" property="og:title" content={title} />
    <meta
      name="og:description"
      property="og:description"
      content={description ? description : defaultMeta.description}
    />
    {image ? (
      <meta property="og:image" content={`${image}`} />
    ) : (
      <meta property="og:image" content={`${defaultMeta.image}`} />
    )}
    <meta
      property="og:site_name"
      content={`${canonical ? canonical : defaultMeta.canonical}`}
    />
    <meta
      property="og:url"
      content={`${canonical ? canonical : defaultMeta.canonical}`}
    />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={title} />
    <meta
      name="twitter:description"
      content={description ? description : defaultMeta.description}
    />
    <meta
      name="twitter:site"
      content={`${canonical ? "@" + canonical : "@" + defaultMeta.canonical}`}
    />
    <meta
      name="twitter:creator"
      content={`${canonical ? "@" + canonical : "@" + defaultMeta.canonical}`}
    />
    {image ? (
      <meta name="twitter:image" content={`${image}`} />
    ) : (
      <meta name="twitter:image" content={`${defaultMeta.image}`} />
    )}

    {canonical && (
      <link
        rel="canonical"
        href={`${canonical ? canonical : defaultMeta.canonical}`}
      />
    )}

    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon.ico" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon.ico" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
    <meta name="apple-mobile-web-app-title" content="whereishot" />
    <meta name="application-name" content="whereishot" />
    <meta name="msapplication-TileColor" content="#da532c" />
    <meta name="theme-color" content="#ffffff" />
  </Head>
);

export default SEO;
