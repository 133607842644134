import styled from "styled-components";

export const ProductCardContainer = styled.div`
  /* width: 180px; */
  background-color: #fefefe;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.035);
  height: 100%;

  box-shadow: 0px 0px 5.3px rgba(0, 0, 0, 0.035),
    0px 0px 17.9px rgba(0, 0, 0, 0.046), 0px 0px 80px rgba(0, 0, 0, 0.07);

  &:hover {
    cursor: pointer;
    border: 1px solid #5555;
  }

  .product_card_container {
    width: 100%;
    height: auto;
    padding: 10px 0px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .ml_5_px {
    margin-left: 5px;
  }

  .mb_4_px {
    margin-bottom: 4px;
  }

  .card-title {
    margin-bottom: 10px;
    font-size: 14px;
    color: black;
  }

  .card-price {
    font-size: 18px;
    font-weight: 600;
    color: red;
  }

  .card-info {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: #999;
    margin-bottom: 10px;
  }
  .card-like {
    font-size: 14px;
    font-weight: 500;
    color: black;
    text-decoration: line-through;
  }
`;
