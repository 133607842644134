import styled from "styled-components";

export const ProductByCategoryListContainer = styled.div`
  .tooltips_icon {
    margin-left: 10px;
    margin-bottom: 2px;

    &:hover {
      cursor: pointer;
    }
  }

  .product_content_container {
    row-gap: 60px;

    @media (min-width: 992px) {
      .col-lg-3 {
        width: 20%;
      }
    }
  }
`;
