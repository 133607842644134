import React, { useRef } from "react";
import { ProductCardContainer } from "./ProductCard.styled";
import Link from "next/link";

interface ProductCardProps {
  id?: number;
  image?: string;
  googleCategoryId?: string;
  title?: string;
  originalPrice?: any;
  price?: any;
  markdownPercent?: number;
  redirectUrl?: string;
  merchantName?: string;
}

const ProductCard: React.FC<ProductCardProps> = ({
  id,
  image,
  googleCategoryId,
  title,
  markdownPercent,
  originalPrice,
  price,
  merchantName,
  redirectUrl,
}) => {
  const redirectRef: any = useRef();

  const sanitizeSlug = (slug?: string) => {
    const given_slug: any = slug
      ?.replace(/\'/g, "")
      ?.replace(/\/|\&|\-|\(|\)/g, " ")
      ?.split(" ");

    let new_slug: any = [];

    if (given_slug?.length > 5) {
      for (let i = 0; i < 5; i++) {
        new_slug.push(given_slug[i]);
      }
      return (new_slug.join("-") + "/").toLowerCase();
    } else {
      return (given_slug.join("-") + "/").toLowerCase();
    }
  };

  const sanitizeAlt = (alt?: string) => {
    const given_alt: any = alt
      ?.replace(/\'/g, "")
      ?.replace(/\/|\&|\-|\(|\)/g, " ")
      ?.split(" ");
    return (given_alt.join("-") + "/").toLowerCase();
  };

  let renderPrice: any;

  if (markdownPercent) {
    renderPrice = (
      <div className="d-flex align-items-center">
        <p className="card-like">
          {originalPrice?.Value ? originalPrice?.Value : "N/A"}
        </p>
        <p className="card-price ml_5_px">
          {price?.Value ? price?.Value : "N/A"}
        </p>
      </div>
    );
  } else {
    renderPrice = (
      <div className="d-flex ">
        <p className="card-price">{price?.Value ? price?.Value : "N/A"}</p>
      </div>
    );
  }

  return (
    <Link href={`/product/${title ? sanitizeSlug(title) : undefined}${id}`}>
      <a style={{ textDecoration: "none" }}>
        <ProductCardContainer>
          <div className="product_card_container">
            <img
              src={
                image
                  ? image
                  : "https://slickdeals.net/attachment/6/4/6/0/9/9/5/200x200/11458927.thumb"
              }
              alt={title ? sanitizeAlt(title) : "N/A"}
            />
          </div>
          <p className="card-info">
            {googleCategoryId ? googleCategoryId : "N/A"}
          </p>

          <p className="card-title">{title ? title : "N/A"}</p>

          <p className="card-info mb_4_px">
            Brand: {merchantName ? merchantName : "N/A"}
          </p>

          {renderPrice}

          <p className="card-info">
            Discount: {markdownPercent ? markdownPercent + "%" : "N/A"}
          </p>
        </ProductCardContainer>
      </a>
    </Link>
  );
};

export default ProductCard;
