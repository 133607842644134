import Image from "next/image";
import React, { useState } from "react";
import { Button, Col, FormControl, Modal, Row } from "react-bootstrap";
import { NewsletterModalContainer } from "./NewsletterModal.styled";

const NewsletterModal = () => {
  const [show, setShow] = useState(true);
  const [input, setInput] = useState<string | undefined>(undefined);

  const validateEmail = (email: string | undefined) => {
    return email?.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const onHandleJoin = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (!validateEmail(input)) {
      window.alert("invalid email");
      return;
    }
    setShow(false);
  };

  return (
    <NewsletterModalContainer
      show={show}
      centered
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h2>Join our newsletter</h2>
        <h6 className="mt-2">
          get weekly access to our best deals, tips and tricks.
        </h6>

        <div className="d-flex justify-content-center mt-5">
          <Image
            src="/newsletter.jpg"
            width={500}
            height={240}
            alt="where is hot news"
          />
        </div>

        <Row className="mt-5">
          <Col md={9}>
            <FormControl
              className="email_input"
              placeholder="Email"
              type="input"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </Col>
          <Col md={3}>
            <Button className="join_btn" onClick={onHandleJoin}>
              JOIN
            </Button>
          </Col>
        </Row>
        <h6 className="mt-1 input_notice mb-4">
          No affiliate links, no spam, no data sharing.
        </h6>
      </Modal.Body>
    </NewsletterModalContainer>
  );
};

export default NewsletterModal;
