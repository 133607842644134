import React from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ProductCard from "../ProductCard/ProductCard";
import { ProductByCategoryListContainer } from "./ProductByCategoryList.styled";
import { AiFillInfoCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";

interface ProductByCategoryListProps {
  title?: string;
  data?: any;
  tooltip?: any;
}

const ProductByCategoryList: React.FC<ProductByCategoryListProps> = ({
  title,
  data,
  tooltip,
}) => {
  const renderTooltip = (props: any) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {tooltip ? tooltip : "Abit of desc"}
      </Tooltip>
    );
  };

  return (
    <ProductByCategoryListContainer className="container mb-5">
      <h5 className="mb-3 d-flex">
        {title ? title : "Featured Deals"}{" "}
        {/* <div style={{ position: "relative" }}>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
          >
            <span>
              <AiFillInfoCircle className="tooltips_icon" />
            </span>
          </OverlayTrigger>
        </div> */}
        <span data-tip={tooltip ? tooltip : "Abit of desc"}>
          <AiFillInfoCircle className="tooltips_icon" />
        </span>
      </h5>
      {/*//@ts-ignore*/}
      <ReactTooltip />

      <Row className="product_content_container">
        {data ? (
          data?.products?.map((item: any) => {
            return (
              <Col lg={3} md={4} sm={6} key={item?.ID}>
                <ProductCard
                  id={item?.ID}
                  title={item?.Title}
                  image={item?.Image}
                  googleCategoryId={item?.GoogleCategoryId}
                  originalPrice={item?.OriginalPrice}
                  price={item?.Price}
                  markdownPercent={item?.MarkdownPercent}
                  redirectUrl={item?.RedirectUrl}
                  merchantName={item?.MerchantName}
                />
              </Col>
            );
          })
        ) : (
          <>
            <Col lg={3} md={4} sm={6}>
              <ProductCard />
            </Col>

            <Col lg={3} md={4} sm={6}>
              <ProductCard />
            </Col>

            <Col lg={3} md={4} sm={6}>
              <ProductCard />
            </Col>

            <Col lg={3} md={4} sm={6}>
              <ProductCard />
            </Col>

            <Col lg={3} md={4} sm={6}>
              <ProductCard />
            </Col>

            <Col lg={3} md={4} sm={6}>
              <ProductCard />
            </Col>
          </>
        )}
      </Row>
    </ProductByCategoryListContainer>
  );
};

export default ProductByCategoryList;
