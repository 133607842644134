import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const NewsletterModalContainer = styled(Modal)`
  .modal-content {
    border-radius: 0px;
  }

  .modal-header {
    border-radius: 0px;
    border-top: 6px solid #0c0e00;
    border-bottom: none;
  }

  .email_input {
    border-radius: 0px;
    border: 1px solid #0c0e00;

    &:focus {
      box-shadow: none;
      /* border: none; */
    }
  }

  .btn-close {
    &:focus {
      box-shadow: none;
    }
  }

  .input_notice {
    font-size: 12px;
  }

  .join_btn {
    width: 100%;
    border-radius: 0px;
    background-color: #0c0e00;
    border-color: #0c0e00;

    &:hover {
      background-color: #0c0e00d5;
    }

    &:focus {
      box-shadow: none;
    }
  }
`;
