import React, { useState } from "react";
import { Carousel, Tooltip } from "react-bootstrap";
// import { AiFillInfoCircle } from "react-icons/ai";
// import { FaChevronRight } from "react-icons/fa";
// import PostCard from "../../components/Posts/PostCard/PostCard";
import ProductByCategoryList from "../../components/Product/ProductByCategoryList/ProductByCategoryList";
// import StoreCard from "../../components/Stores/StoreCard/StoreCard";
// import { PostScreenContainer } from "../Post/PostScreen.styled";
// import { dummyStores } from "./dummyStores";
import { HomeScreenContainer } from "./HomeScreen.styled";
import Image from "next/image";
import NewsletterModal from "../../components/NewsletterModal/NewsletterModal";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

interface HomeScreenProps {
  data?: any;
  posts?: any;
}

const HomeScreen: React.FC<HomeScreenProps> = (props) => {
  const router = useRouter();
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);

  const carouselBg = ["#2C4A88", "#8E6341", "#A49B96"];

  const [currentCarouselBg, setCurrentCarouselBg] = useState(carouselBg[0]);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
    setCurrentCarouselBg(carouselBg[selectedIndex]);
  };

  return (
    <HomeScreenContainer>
      {router?.query?.newsletter === "1" ? <NewsletterModal /> : undefined}
      <div className="home_screen_container">
        <div style={{ backgroundColor: currentCarouselBg }}>
          <div className="container mb-5">
            <Carousel activeIndex={index} onSelect={handleSelect}>
              <Carousel.Item className="home_carousel_item">
                <Image
                  src="/images/home-and-garden-home.jpg"
                  alt="home and garden"
                  layout="fill"
                />
              </Carousel.Item>
              <Carousel.Item className="home_carousel_item">
                <Image
                  src="/images/electronics-home.jpg"
                  alt="electronics"
                  layout="fill"
                />
              </Carousel.Item>
              <Carousel.Item className="home_carousel_item">
                <Image
                  src="/images/furnitures-home.jpg"
                  alt="furnitures"
                  layout="fill"
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <ProductByCategoryList
          title={t("home:content.featured_deals.title")}
          tooltip={"List of high recommended products"}
          data={props?.data}
        />

        {/* <ProductByCategoryList title="Trendy Deals" data={props?.data} />

        <div className="home_store_container mb-5">
          <div className="container">
            <div className="mb-3">
              <h1 className="home_store_title">
                Top Stores{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) =>
                    renderTooltip(
                      props,
                      "Top Stores that might pique your interest"
                    )
                  }
                >
                  <a>
                    <AiFillInfoCircle className="tooltips_icon" />
                  </a>
                </OverlayTrigger>
                <a href="/stores" className="more_review">
                  More <FaChevronRight />
                </a>
              </h1>
            </div>

            {dummyStores?.length > 0 ? (
              <div className="store-feed">
                {dummyStores?.map((item: any) => {
                  return <StoreCard store={item} key={item?.id} />;
                })}
              </div>
            ) : (
              <h1>No Stores Available</h1>
            )}
          </div>
        </div>

        <PostScreenContainer className="home_post_container">
          <div className="container">
            <div className="mb-3">
              <h1 className="home_post_title">
                Reviews{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <a>
                    <AiFillInfoCircle className="tooltips_icon" />
                  </a>
                </OverlayTrigger>
                <a href="/posts" className="more_review">
                  More <FaChevronRight />
                </a>
              </h1>
            </div>

            {props?.posts?.posts ? (
              <div className="post-feed">
                {props?.posts?.posts?.map((item: any) => {
                  return <PostCard post={item} key={item?.uuid} />;
                })}
              </div>
            ) : (
              <h1>No Posts Available</h1>
            )}
          </div>
        </PostScreenContainer> */}
      </div>
    </HomeScreenContainer>
  );
};

export default HomeScreen;
