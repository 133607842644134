import styled from "styled-components";
import { ProgressBar } from "react-bootstrap";

export const CustomProgressBar = styled(ProgressBar)`
  border-radius: 0px;
  height: 0.5rem;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
`;
