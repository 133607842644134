import styled from "styled-components";

export const HomeScreenContainer = styled.div`
  .home_screen_container {
    margin: 0px 0px 50px 0px;
  }

  .tooltips_icon {
    margin-left: 10px;
    margin-bottom: 2px;

    &:hover {
      cursor: pointer;
    }
  }

  .more_review {
    float: right;
    color: #266797;
    text-decoration: none;
    font-size: 16px;
    margin-top: 4px;
  }

  .home_post_container {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .home_carousel_item {
    height: 345px;
  }

  .home_post_title {
    font-size: 1.25rem;
  }

  .post-feed {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    @media screen and (max-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;

      .post-card-image {
        height: 300px;
      }
    }

    .card {
      border-color: white;
      box-shadow: 0px 0px 5.3px rgb(0 0 0 / 4%), 0px 0px 17.9px rgb(0 0 0 / 5%),
        0px 0px 80px rgb(0 0 0 / 7%);
    }

    .post-card-title {
      margin-bottom: 10px;
      font-size: 14px;
    }

    .post-card-excerpt {
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      color: #999;
      margin-bottom: 10px;
      line-height: 1.55em;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .post-card-footer-right {
      div {
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        color: #999;
      }
    }
  }

  .sub_navbar {
    padding: 5px 0px;
    background-color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;

    .sub_navbar_title {
      color: #fefefefe;
      font-weight: 600;
    }

    .sub_navbar_text {
      color: #fefefefe;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .home_store_container {
    .home_store_title {
      font-size: 1.25rem;
    }

    .store-feed {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

      grid-gap: 10px;

      @media screen and (max-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;

        .store-card-image {
          height: 300px;
        }
      }
    }
  }
`;
