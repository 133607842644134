import React, { useEffect, useState } from "react";
import { CustomProgressBar } from "./AnimatedProgressBar.styled";

const AnimatedProgressBar = () => {
  const [currentProgress, setCurrentProgress] = useState<any>(0);
  const [isShow, setIsShow] = useState<boolean | undefined>(true);

  useEffect(() => {
    setTimeout(() => {
      setCurrentProgress(100);
    }, 100);

    setTimeout(() => {
      setIsShow(false);
    }, 1000);
  }, []);

  return (
    <CustomProgressBar
      now={currentProgress}
      style={{ display: !isShow ? "none" : undefined }}
      visuallyHidden
    />
  );
};

export default AnimatedProgressBar;
