
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import type { NextPage } from "next";
import React, { Fragment } from "react";
import AnimatedProgressBar from "../components/AnimatedProgressBar/AnimatedProgressBar";
import SEO from "../components/SEO/SEO";
import HomeScreen from "../screens/Home/HomeScreen";

const Home: NextPage = (props: any) => {
  return (
    <Fragment>
      <SEO canonical="https://whereishot.com/" />

      <AnimatedProgressBar />

      <HomeScreen data={props.data} posts={props.posts} />
    </Fragment>
  );
};

export default Home;

 async function _getServerSideProps(context: any) {
  // Fetch data from external API
  const region = process.env.NEXT_PUBLIC_DEFAULT_REGION
    ? process.env.NEXT_PUBLIC_DEFAULT_REGION
    : "US";

  const res = await fetch(
    `https://backend.whereishot.com/products-top-discounts?region=` + region
  );

  const posts_res = await fetch(
    process.env.NEXT_PUBLIC_GHOST_URL +
      "content/posts?key=" +
      process.env.NEXT_PUBLIC_GHOST_CONTENT_KEY +
      "&limit=5"
  );

  const data = await res.json();
  // console.log(data);

  const posts = await posts_res.json();

  // Pass data to the page via props
  return { props: { data, posts } };
}


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  